<script setup>
    import { CheckoutStatus, InvoiceStatus } from '~/enum';

    const { t: $t } = useI18n();

    const emit = defineEmits(['nextStep']);

    const props = defineProps({
        checkout: {
            type: Object,
            required: true
        },

        transportAmount: {
            default: null,
        },

        vatPercentage: {
            default: null,
        },

        transportVatPercentage: {
            default: null,
        },

        files: {
            type: Array,
            default: () => [],
        },

        uploadedFiles: {
            type: Array,
            default: () => [],
        },

        disabled: {
            type: Boolean,
            default: false,
        }
    });

    const mainInvoice = computed(() => {
        return props.checkout.invoices.filter(invoice => {
            return invoice.template === 'order';
        })[0];
    });

    const transportInvoice = computed(() => {
        return props.checkout.invoices.filter(invoice => {
            return invoice.template === 'transport';
        })[0];
    });

    const transportAmount = computed(() => {
        if (transportInvoice.value?.status === InvoiceStatus.PAID) {
            return null;
        }

        return props.transportAmount || transportInvoice?.total_ht;
    });

    const transportVat = computed(() => {
        if (transportInvoice.value?.status === InvoiceStatus.PAID) {
            return null;
        }

        return props.transportAmount
            ? props.transportAmount * props.transportVatPercentage / 100
            : transportInvoice?.total_vat;
    });

    const order = computed(() => {
        return mainInvoice.value.order;
    });

    const isDisabled = computed(() => {
        let filesLength = props.files?.length + props.uploadedFiles.filter(file => ! file.deleted)?.length;

        return props.disabled
            || (
                props.checkout.status === CheckoutStatus.CREATED
                    ? false
                    : ! filesLength
            );
    });

    const totalTtc = computed(() => {
        return (
            parseFloat(mainInvoice.value.total_ttc)
            + parseFloat(transportAmount.value || 0)
            + parseFloat(transportVat.value || 0)
        ).toFixed(2);
    });

    const submit = () => {
        if (! isDisabled.value) {
            emit('nextStep');
        }
    }
</script>

<template>
    <Card>
        <h2>{{ $t('Summary') }}</h2>

        <div id="checkout__cart__header">
            <NuxtImg
                v-if="order.offer.illustration"
                :src="order.offer.illustration"
                alt="Offer illustration"
            />

            <div id="checkou__cart__header__title">
                <div>#{{ order.offer.id }}</div>
                <b>{{ order.offer.title }}</b>
                <PrimevueTag>{{ order.offer.quality_name }}</PrimevueTag>
            </div>
        </div>

        <table id="checkout__cart__offer">
            <tbody>
                <tr>
                    <th>{{ $t('Offer') }}</th>
                    <td>#{{ order.offer.id }}</td>
                </tr>

                <tr>
                    <th>{{ $t('Validated at') }}</th>
                    <td>{{ asDate(order.validated_at, true) }}</td>
                </tr>

                <tr>
                    <th>{{ $t('Number of pallets') }}</th>
                    <td>{{ order.offer.number_pallets }}</td>
                </tr>

                <tr>
                    <th>{{ $t('Number of pieces') }}</th>
                    <td>{{ order.offer.total_sku }}</td>
                </tr>
            </tbody>
        </table>

        <table id="checkout__cart__amounts">
            <tbody>
                <tr>
                    <td>{{ $t('Last bid') }}</td>
                    <th>{{ asMoney(mainInvoice.total_ht) }}</th>
                </tr>

                <tr v-if="mainInvoice.total_vat">
                    <td>{{ $t('VAT') }} {{ props.vatPercentage }}% ({{ $t('Bid') }})</td>
                    <th>{{ asMoney(mainInvoice.total_vat) }}</th>
                </tr>
            </tbody>
        </table>

        <table v-if="transportAmount">
            <tbody>
                <tr>
                    <td>{{ $t('Transport') }}</td>
                    <th>{{ asMoney(transportAmount) }}</th>
                </tr>

                <tr>
                    <td>{{ $t('VAT') }} {{ props.transportVatPercentage }}% ({{ $t('Transport') }})</td>
                    <th>{{ asMoney(transportVat) }}</th>
                </tr>
            </tbody>
        </table>

        <div id="checkout__cart__divider"></div>

        <table id="checkout__cart__total">
            <tbody>
                <tr>
                    <th id="checkout__cart__label">{{ $t('Total') }}</th>
                    <th id="checkout__cart__amount">
                        {{ asMoney(totalTtc) }}
                        <CopyButton :value="totalTtc" />
                    </th>
                </tr>
            </tbody>
        </table>

        <div>
            <PrimevueButton
                :label="props.checkout.status === CheckoutStatus.CREATED ? $t('Go to the next step') : $t('I\'ve paid')"
                severity="success"
                class="p-button-xs w-full"
                :disabled="isDisabled"
                @click="submit"
            />
        </div>
    </Card>
</template>
