<script setup>
    const emit = defineEmits(['nextStep']);

    const props = defineProps({
        checkout: {
            type: Object,
            required: true,
        },

        showSteps: {
            type: Boolean,
            default: true,
        },

        transportAmount: {
            default: null,
        },

        vatPercentage: {
            default: null,
        },

        transportVatPercentage: {
            default: null,
        },

        files: {
            type: Array,
            default: () => [],
        },

        uploadedFiles: {
            type: Array,
            default: () => [],
        },

        disabled: {
            type: Boolean,
            default: false,
        }
    });

    // Define page title
    useHead({
        title: 'Checkout',
    });
</script>

<template>
    <NuxtLayout name="frontend" :fullScreen="true">
        <CheckoutSteps v-if="props.showSteps" :checkoutId="props.checkout.id" class="my-5" />

        <section id="checkout__container">
            <article id="checkout__payment">
                <slot />
            </article>

            <aside id="checkout__cart">
                <CheckoutCart
                    :checkout="props.checkout"
                    :transportAmount="props.transportAmount"
                    :vatPercentage="props.vatPercentage"
                    :transportVatPercentage="props.transportVatPercentage"
                    :files="props.files"
                    :uploadedFiles="props.uploadedFiles"
                    :disabled="props.disabled"
                    @nextStep="emit('nextStep', props.files)"
                />

                <Card
                    id="checkout__question"
                    :pt="{
                        body: {
                            class: 'flex-row justify-content-between flex-wrap column-gap-8'
                        }
                    }"
                >
                    <template #body>
                        <div class="flex gap-3">
                            <Icon name="ic:baseline-help" class="text-4xl" />
                            <div>
                                <div><b>{{ $t('Need help?') }}</b></div>
                                <div>{{ $t('We\'re here for you.') }}</div>
                            </div>
                        </div>

                        <div id="offer__question__button" class="flex-1">
                            <OpenHubspotChat name="marketplace-detail" />
                        </div>
                    </template>
                </Card>
            </aside>
        </section>
    </NuxtLayout>
</template>
