<script setup>
    const props = defineProps({
        checkoutId: {
            type: Number,
            required: true
        }
    });
</script>

<template>
    <!-- Register steps -->
    <PrimevueSteps :model="[
        {label: $t('Transport'), to: localePath({name: 'checkout-id', params: {id: props.checkoutId }})},
        {label: $t('Payment'), to: localePath({name: 'checkout-id-payment', params: {id: props.checkoutId }})},
    ]" />
</template>
